import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/Layout"

import "../styles/404.scss"

export default function NotFound() {
  return (
    <Layout>
      <div className="not-found">
        <StaticImage
          className="tree-left"
          src="../images/tree-left-no-coins.png"
          alt="tree-left"
        />
        <StaticImage
          className="tree-right"
          src="../images/tree-right-no-coins.png"
          alt="tree-right"
        />
        <div className="content">
          <h1>Page not found</h1>
          <h2>We’re sorry - the page you were looking for doesn’t exist.</h2>
          <Link className="button" to="/">
            GO TO HOME PAGE
          </Link>
          <StaticImage className="main-img" src="../images/404.png" alt="404" />
        </div>
      </div>
    </Layout>
  )
}
